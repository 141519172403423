import { Button } from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from "../../../context/theme-context";

export const ActionButton: React.FC<{ title: string; onClick: () => void }> = (
  props
) => {
  const themeCtx = useContext(ThemeContext);
  return (
    <Button
      variant="outlined"
      color="primary"
      sx={{ color: themeCtx.theme.text }}
      onClick={props.onClick}
    >
      {props.title}
    </Button>
  );
};
