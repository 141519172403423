import React, { useState } from "react";
import { useParams } from "react-router-dom";
import LightBox from "../../../components/core/LightBox";
import { PortfolioItemType } from "../_data";
import { getPortfolioDetails } from "./helper";

import styled from "./PortfolioDetail.module.css";
import Banner from "../../../components/core/Banner";
import { Button } from "@mui/material";
import { Android, Apple } from "@mui/icons-material";
import { openNewWindow } from "../../../utilities/function";

const PortfolioDetail = () => {
  const { portfolioId } = useParams();
  const [data, setData] = useState<PortfolioItemType>();

  React.useEffect(() => {
    const data = getPortfolioDetails(portfolioId);
    setData(data);
  }, [portfolioId]);

  const onOpenLink = (url: string) => {
    openNewWindow(url);
  };

  return (
    <>
      <Banner />
      <div className={styled.titleContainer}>
        <h1 className={styled.title}>{data?.title}</h1>

        <p className={styled.body}>◉ {data?.body}</p>
        <div
          style={{
            display: "flex",
            paddingBottom: 18,
          }}
        >
          {data?.android != '' && <Button
            size="small"
            variant="outlined"
            color="secondary"
            startIcon={<Android />}
            onClick={() => onOpenLink(data?.android || "")}
          >
            ANDROID
          </Button>}
          <div style={{ paddingRight: 18 }}></div>
          {data?.ios != '' && <Button
            size="small"
            variant="outlined"
            color="secondary"
            startIcon={<Apple />}
            onClick={() => onOpenLink(data?.ios || "")}
          >
            IOS
          </Button>}
        </div>
      </div>

      <LightBox images={data?.images} />
    </>
  );
};

export default PortfolioDetail;
