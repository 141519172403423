import { Avatar } from "@mui/material";
import React, { useContext } from "react";
import PROFILE from "./../../assets/Profile3D.png";
import { ThemeContext } from "../../context/theme-context";
import styled from "./Banner.module.css";
import { useLocation, useNavigate } from "react-router-dom";

export default function Banner() {
  const themeCtx = useContext(ThemeContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  let isProURL = false;

  console.log("pathname---->>>", pathname, pathname.search("/pro") !== -1);

  if (pathname.search("/pro") !== -1) isProURL = true;

  const handleLogoClick = () => {
    if (isProURL) {
      navigate("/pro");
    } else navigate("/");
  };

  return (
    <div className={styled.container}>
      <div className={styled.logoContainer} onClick={handleLogoClick}>
        <div>
          <Avatar
            alt="Nayan Vekariya"
            src={PROFILE}
            sx={{
              width: 80,
              height: 80,
            }}
          />
        </div>
        <div style={{ paddingLeft: 10 }}>
          <h4 className={styled.name} style={{ color: themeCtx.theme.title }}>
            Nayan Vekariya
          </h4>
          <p className={styled.subtitle} style={{ color: themeCtx.theme.text }}>
            Senior Software Developer
          </p>
        </div>
      </div>
    </div>
  );
}
