import React, { useContext } from "react";

import styled from "./ProductPage.module.css";

import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { ThemeContext } from "../../context/theme-context";

import { useNavigate, useLocation } from "react-router-dom";
import { PORTFOLIO_DATA } from "./_data";
import { openNewWindow } from "../../utilities/function";

const PortfolioPage = () => {
  const { theme: COLOR } = useContext(ThemeContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let isProURL = false;

  let PortfolioData = PORTFOLIO_DATA;

  return (
    <>
      <div className={styled.titleContainer}>
        <h1 className={styled.title}>Products</h1>
        {/*  <p className={styled.body}>◉ Youtube</p> */}
      </div>

      <div className={styled.container}>
        {PortfolioData.map((v) => (
          <Card
            className={styled.cardContainer}
            onClick={() => openNewWindow(v.link)}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                height="400"
                image={v.image}
                alt="green iguana"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{ color: COLOR.black }}
                >
                  {v.title}
                </Typography>
                {/* <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{ color: COLOR.black }}
                >
                  {v.body}
                </Typography> */}
              </CardContent>
            </CardActionArea>
            {v.youtube && (
              <CardActions>
                <Button
                  size="small"
                  color="secondary"
                  onClick={() => openNewWindow(v.youtube)}
                >
                  Youtube
                </Button>
                {/*  <Button
                size="small"
                color="secondary"
                onClick={() => openNewWindow(v.ios)}
              >
                IOS
              </Button> */}
              </CardActions>
            )}
          </Card>
        ))}
      </div>
    </>
  );
};

export default PortfolioPage;
