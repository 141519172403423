import "./App.css";
import { AboutPage } from "./pages/about/AboutPage";
import PortfolioPage from "./pages/portfolio/PortfolioPage";
import ThemeContextProvider from "./context/theme-context";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NoPage from "./pages/no-page/NoPage";
import PortfolioDetail from "./pages/portfolio/portfolio-detail/PortfolioDetail";
import ProductPage from "./pages/product/ProductPage";

function App() {
  const HomePage = (
    <>
      <AboutPage />
      <PortfolioPage />
    </>
  );

  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: <NoPage />,
      children: [
        {
          path: "",
          element: HomePage,
        },
        { path: "portfolio", element: <PortfolioPage /> },
        { path: "portfolio/:portfolioId", element: <PortfolioDetail /> },
        { path: "about", element: <AboutPage /> },
        { path: "products", element: <ProductPage /> },
      ],
    },
    {
      path: "/pro",
      children: [
        { path: "", element: HomePage },
        { path: "portfolio", element: <PortfolioPage /> },
        { path: "portfolio/:portfolioId", element: <PortfolioDetail /> },
        { path: "about", element: <AboutPage /> },
        { path: "products", element: <ProductPage /> },
      ],
    },
  ]);

  return (
    <ThemeContextProvider>
      <RouterProvider router={router} />
    </ThemeContextProvider>
  );
}

export default App;
