import { Avatar, Button } from "@mui/material";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "../../context/theme-context";

import PROFILE from "./../../assets/Profile3D.png";

import styled from "./AboutPage.module.css";
import {
  BsFillCartCheckFill,
  BsGithub,
  BsInstagram,
  BsLinkedin,
} from "react-icons/bs";
import { AiOutlineYoutube } from "react-icons/ai";
import { URL } from "../../utilities/constants";
import { ActionButton } from "./components/ActionButton";
import { openNewWindow } from "../../utilities/function";

export const AboutPage = () => {
  const themeCtx = useContext(ThemeContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  let isProURL = false;

  if (pathname.search("/pro") !== -1) isProURL = true;

  const handleResumeClick = () => {
    let resume = URL.RESUME;
    if (isProURL) resume = URL.RESUME_PRO;
    openNewWindow(resume);
  };

  const SocialMediaIcons = (
    <div style={{ paddingTop: 16 }}>
      <Button
        style={{
          color: "black",
        }}
        onClick={() => openNewWindow(URL.LINKED_IN)}
      >
        <BsLinkedin size={25} />
      </Button>
      <Button
        style={{
          color: "black",
        }}
        onClick={() => openNewWindow(URL.GIT_HUB)}
      >
        <BsGithub size={25} />
      </Button>
      <Button
        style={{
          color: "black",
        }}
        onClick={() => openNewWindow(URL.YOUTUBE)}
      >
        <AiOutlineYoutube
          size={50}
          style={{ paddingRight: 12, paddingLeft: 12 }}
        />
      </Button>
      <Button
        style={{
          color: "black",
        }}
        onClick={() => openNewWindow(URL.INSTAGRAM)}
      >
        <BsInstagram size={25} />
      </Button>

      <Button
        style={{
          color: "black",
        }}
        onClick={() => {
          navigate(`products`);
        }}
      >
        <BsFillCartCheckFill size={26} style={{ paddingLeft: 18 }} />
      </Button>
    </div>
  );

  const Action = (
    <div className={styled.buttonContainer}>
      <ActionButton
        title="Hire Me"
        onClick={() => {
          if (isProURL) openNewWindow(URL.UP_WORK);
          else {
            window.open(
              "mailto:nayan.veda@gmail.com?subject=Inquiry from website",
              "_self"
            );
          }
        }}
      />
      <div style={{ paddingRight: 10, paddingLeft: 10 }}>
        <ActionButton title="Resume" onClick={handleResumeClick} />
      </div>
    </div>
  );

  return (
    <div className={styled.container}>
      <p className={styled.godText}>જય શ્રી સ્વામિનારાયણ</p>
      <div className={styled.aboutContainer}>
        <Avatar
          alt="Nayan Vekariya"
          src={PROFILE}
          sx={{
            width: 280,
            height: 280,
          }}
        />
        <h1 className={styled.name} style={{ color: themeCtx.theme.title }}>
          Nayan Vekariya
        </h1>
        <p className={styled.subtitle} style={{ color: themeCtx.theme.text }}>
          Senior Software Developer
        </p>
      </div>
      {Action}
      {SocialMediaIcons}
    </div>
  );
};
