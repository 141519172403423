import PANTALOONS from "./../../assets/portfolio/pantaloons_cover.png";
import IQVIA from "./../../assets/portfolio/iqvia_cover.png";
import PINOT from "./../../assets/portfolio/pinot_cover.png";
import SEVAK from "./../../assets/portfolio/sevak_cover.png";
import EMPIRX from "./../../assets/portfolio/empirx_cover.png";
import EVERGY from "./../../assets/portfolio/evergy_cover.png";
import ATKINS from "./../../assets/portfolio/atkins_cover.png";

// Pantaloons image
import ATKINS_1 from "./../../assets/portfolio/atkins/Dashboard.png";
import ATKINS_2 from "./../../assets/portfolio/atkins/Food.png";
import ATKINS_3 from "./../../assets/portfolio/atkins/Weight.png";
import ATKINS_4 from "./../../assets/portfolio/atkins/measurements.png";
import ATKINS_5 from "./../../assets/portfolio/atkins/My_Fitness.png";
import ATKINS_6 from "./../../assets/portfolio/atkins/My_water.png";
import ATKINS_7 from "./../../assets/portfolio/atkins/Login.png";
import ATKINS_8 from "./../../assets/portfolio/atkins/Signup_1.png";
import ATKINS_9 from "./../../assets/portfolio/atkins/Signup_2.png";
import ATKINS_10 from "./../../assets/portfolio/atkins/Signup_3.png";
import ATKINS_11 from "./../../assets/portfolio/atkins/Signup_4.png";
// Pantaloons image
import PANTALOONS_1 from "./../../assets/portfolio/pantaloons/cart.png";
import PANTALOONS_2 from "./../../assets/portfolio/pantaloons/category.png";
import PANTALOONS_3 from "./../../assets/portfolio/pantaloons/dashboard.png";
import PANTALOONS_4 from "./../../assets/portfolio/pantaloons/login.png";
import PANTALOONS_5 from "./../../assets/portfolio/pantaloons/product_Detail.png";
import PANTALOONS_6 from "./../../assets/portfolio/pantaloons/product_List.png";
import PANTALOONS_7 from "./../../assets/portfolio/pantaloons/profile.png";
import PANTALOONS_8 from "./../../assets/portfolio/pantaloons/women.png";

// IQVIA image
import IQVIA_1 from "./../../assets/portfolio/iqvia/appointment_list.png";
import IQVIA_2 from "./../../assets/portfolio/iqvia/dashboard.png";
import IQVIA_3 from "./../../assets/portfolio/iqvia/offer.png";
import IQVIA_4 from "./../../assets/portfolio/iqvia/offer_accepted.png";
import IQVIA_5 from "./../../assets/portfolio/iqvia/offer_detail.png";
import IQVIA_6 from "./../../assets/portfolio/iqvia/splash.png";

// Pinot image
import PINOT_1 from "./../../assets/portfolio/pinot/booking.png";
import PINOT_2 from "./../../assets/portfolio/pinot/dashboard.png";
import PINOT_3 from "./../../assets/portfolio/pinot/login.png";
import PINOT_4 from "./../../assets/portfolio/pinot/orders.png";
import PINOT_5 from "./../../assets/portfolio/pinot/studio.png";
import PINOT_6 from "./../../assets/portfolio/pinot/user.png";

// Sevak image
import SEVAK_1 from "./../../assets/portfolio/sevak/dashboard.png";
import SEVAK_2 from "./../../assets/portfolio/sevak/dashboard1.png";
import SEVAK_3 from "./../../assets/portfolio/sevak/login.png";
import SEVAK_4 from "./../../assets/portfolio/sevak/offer.png";
import SEVAK_5 from "./../../assets/portfolio/sevak/products.png";
import SEVAK_6 from "./../../assets/portfolio/sevak/register.png";

// Empirx image
import EMPIRX_1 from "./../../assets/portfolio/empirx/1_Flash.png";
import EMPIRX_2 from "./../../assets/portfolio/empirx/2 Home.png";
import EMPIRX_3 from "./../../assets/portfolio/empirx/3.png";
import EMPIRX_4 from "./../../assets/portfolio/empirx/4.png";
import EMPIRX_5 from "./../../assets/portfolio/empirx/5.png";
import EMPIRX_6 from "./../../assets/portfolio/empirx/6.png";
import EMPIRX_7 from "./../../assets/portfolio/empirx/7.png";

// Empirx image
import EVERGY_1 from "./../../assets/portfolio/evergy/1.png";
import EVERGY_2 from "./../../assets/portfolio/evergy/2.png";
import EVERGY_3 from "./../../assets/portfolio/evergy/3.png";
import EVERGY_4 from "./../../assets/portfolio/evergy/4.png";
import EVERGY_5 from "./../../assets/portfolio/evergy/5.png";

const PORTFOLIO_ID = {
  pantaloons: "pantaloons",
  iqvia: "iqvia",
  pinot: "pinot",
  empirx: "empirx",
  evergy: "evergy",
  sevak: "sevak",
  atkins: "atkins",
};

export type PortfolioItemType = {
  id: string;
  isCurrentCompany: boolean;
  title: string;
  body: string;
  image: string;
  android: string;
  ios: string;
  images: {
    src: string;
    width: number;
    height: number;
  }[];
};

const PIXEL4XL_WEIGHT = 4.3;
const PIXEL4XL_HEIGHT = 9;
const WEB_WEIGHT = 12;
const WEB_HEIGHT = 9;

export const PORTFOLIO_DATA: PortfolioItemType[] = [
  {
    id: PORTFOLIO_ID.atkins,
    isCurrentCompany: true,
    title: "Atkins",
    body: "Atkins® Carb Counter & Meal Tracker is an essential tool to achieving your weight loss goals. The new & redesigned Atkins app allows you to maintain a healthy lifestyle by giving you the diet tools to set daily goals, log meals, & track net carbs.",
    image: ATKINS,
    android: "",
    ios: "",
    images: [
      {
        src: ATKINS_1,
        width: WEB_WEIGHT,
        height: WEB_HEIGHT,
      },
      {
        src: ATKINS_2,
        width: WEB_WEIGHT,
        height: WEB_HEIGHT,
      },
      {
        src: ATKINS_3,
        width: WEB_WEIGHT,
        height: WEB_HEIGHT,
      },
      {
        src: ATKINS_4,
        width: WEB_WEIGHT,
        height: WEB_HEIGHT,
      },
      {
        src: ATKINS_5,
        width: WEB_WEIGHT,
        height: WEB_HEIGHT,
      },
      {
        src: ATKINS_6,
        width: WEB_WEIGHT,
        height: WEB_HEIGHT,
      },
      {
        src: ATKINS_7,
        width: WEB_WEIGHT,
        height: WEB_HEIGHT,
      },
      {
        src: ATKINS_8,
        width: WEB_WEIGHT,
        height: WEB_HEIGHT,
      },
      {
        src: ATKINS_9,
        width: WEB_WEIGHT,
        height: WEB_HEIGHT,
      },
      {
        src: ATKINS_10,
        width: WEB_WEIGHT,
        height: WEB_HEIGHT,
      },
      {
        src: ATKINS_11,
        width: WEB_WEIGHT,
        height: WEB_HEIGHT,
      }
    ],
  },
  {
    id: PORTFOLIO_ID.pantaloons,
    isCurrentCompany: true,
    title: "Pantaloons",
    body: "With over 24 years of retailing experience, Pantaloons, a division of Aditya Birla Fashion and Retail Ltd. is one of the most loved fashion brands in India with 344+ stores spread across 170+ towns and cities in the country.",
    image: PANTALOONS,
    android: "https://play.google.com/store/apps/details?id=com.pantaloons",
    ios: "https://apps.apple.com/in/app/pantaloons-online-shopping-app/id1599439483",
    images: [
      {
        src: PANTALOONS_1,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: PANTALOONS_2,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: PANTALOONS_3,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: PANTALOONS_4,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: PANTALOONS_5,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: PANTALOONS_6,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: PANTALOONS_7,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: PANTALOONS_8,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
    ],
  },
  {
    id: PORTFOLIO_ID.iqvia,
    isCurrentCompany: true,
    title: "IQVIA",
    body: "IQVIA is a leading global provider of advanced analytics, technology solutions and clinical research services to the life sciences industry dedicated to creating intelligent connections that deliver unique innovations and actionable insights.",
    image: IQVIA,
    android:
      "https://play.google.com/store/apps/details?id=com.iqvia.hcp_network",
    ios: "https://apps.apple.com/us/app/iqvia-hcp-network/id1596487934",
    images: [
      {
        src: IQVIA_1,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: IQVIA_2,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: IQVIA_3,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: IQVIA_4,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: IQVIA_5,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: IQVIA_6,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
    ],
  },
  {
    id: PORTFOLIO_ID.empirx,
    isCurrentCompany: true,
    title: "Empirx Health",
    body: "EmpiRx Health puts the pharmacist at the center of the PBM service model, ensuring the most clinically-appropriate and cost-efficient medication therapies that optimize member care and reduce costs. Our clinical teams leverage AI-powered technologies and exclusive population health analytics to make evidence-based medication therapy recommendations.",
    image: EMPIRX,
    android: "https://play.google.com/store/apps/details?id=com.empirx.health",
    ios: "https://apps.apple.com/us/app/empirx-health/id1238289255",
    images: [
      {
        src: EMPIRX_1,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: EMPIRX_2,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: EMPIRX_3,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: EMPIRX_4,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: EMPIRX_5,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: EMPIRX_6,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: EMPIRX_7,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
    ],
  },
  {
    id: PORTFOLIO_ID.evergy,
    isCurrentCompany: true,
    title: "Evergy",
    body: "Evergy Customers will be able to pay their bill, view their bill, report outages, view their Energy Usage, and edit their account profile preferences and notifications. This App will provide an effortless transactional experience whenever customers need to pay their utility bill, report an electrical outage or update how they wish to interact with Evergy.",
    image: EVERGY,
    android: "https://play.google.com/store/apps/details?id=com.evergy.css&hl=en&gl=US",
    ios: "https://apps.apple.com/us/app/evergy-electric/id1642746185",
    images: [
      {
        src: EVERGY_1,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: EVERGY_2,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: EVERGY_3,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: EVERGY_4,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: EVERGY_5,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
    ],
  },
  {
    id: PORTFOLIO_ID.pinot,
    isCurrentCompany: false,
    title: "Pinot",
    body: "Pinot and Picasso is Australia’s largest & fastest growing Paint & Sip franchise, The P&P app, allows our franchisees to seamlessly manage their studio, This app is not designed for customers.",
    image: PINOT,
    android: "https://play.google.com/store/apps/details?id=com.pinot",
    ios: "https://apps.apple.com/us/app/p-p-manager/id1491886760 ",
    images: [
      {
        src: PINOT_1,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: PINOT_2,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: PINOT_3,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: PINOT_4,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: PINOT_5,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: PINOT_6,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
    ],
  },
  {
    id: PORTFOLIO_ID.sevak,
    isCurrentCompany: false,
    title: "Sevak",
    body: "Sevak Education And Charitable Trust in Katargam, Surat is a renowned Charitable Trust that works for the betterment of society. Founded in 2013",
    image: SEVAK,
    android: "",
    ios: "",
    images: [
      {
        src: SEVAK_1,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: SEVAK_2,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: SEVAK_3,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: SEVAK_4,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: SEVAK_5,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
      {
        src: SEVAK_6,
        width: PIXEL4XL_WEIGHT,
        height: PIXEL4XL_HEIGHT,
      },
    ],
  },
];
