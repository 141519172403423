import React, { useState, useSyncExternalStore } from "react";

type ThemeColorObjType = {
  primary: string;
  secondary: string;
  title: string;
  text: string;
  black: string;
  darkgray: string;
};

type ThemeObjType = {
  light: ThemeColorObjType;
};

type ThemeContextType = {
  theme: ThemeColorObjType;
  changeTheme: (theme: string) => void;
};

const THEME: ThemeObjType = {
  light: {
    primary: "brown",
    secondary: "red",
    title: "white",
    text: "ghostwhite",
    black: "black",
    darkgray: 'darkgray'
  },
};

export const ThemeContext = React.createContext<ThemeContextType>({
  theme: THEME.light,
  changeTheme: (theme: string) => {},
});

const ThemeContextProvider = (props: any) => {
  const [theme, setTheme] = useState<ThemeColorObjType>(THEME.light);

  const changeTheme = (theme: string) => {
    // setTheme(THEME[theme]);
  };

  const contextValue: ThemeContextType = {
    changeTheme: changeTheme,
    theme: theme,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
