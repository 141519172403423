import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

import styled from "./PortFolioCard.module.css";
import { useContext } from "react";
import { ThemeContext } from "../../../context/theme-context";
import {
  ShoppingCartRounded,
  AndroidRounded,
  Android,
  AndroidTwoTone,
  IosShare,
  Apple,
} from "@mui/icons-material";

const PortFolioCard = (props: any) => {
  const { theme: COLOR } = useContext(ThemeContext);
  const { item, onClick, onOpenLink } = props;
  return (
    <Card className={styled.cardContainer} onClick={() => onClick(item.id)}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="400"
          image={item.image}
          alt="green iguana"
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            style={{ color: COLOR.black, fontWeight: "bold" }}
          >
            {item.title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ color: COLOR.darkgray }}
          >
            {item.body}
          </Typography>
        </CardContent>
      </CardActionArea>
     {/*  <CardActions>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          startIcon={<Android />}
          onClick={() => onOpenLink(item.android)}
        >
          ANDROID
        </Button>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          startIcon={<Apple />}
          onClick={() => onOpenLink(item.ios)}
        >
          IOS
        </Button>
      </CardActions> */}
    </Card>
  );
};

export default PortFolioCard;
