import React, { useContext } from "react";

import styled from "./PortfolioPage.module.css";

import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { ThemeContext } from "../../context/theme-context";

import { useNavigate, useLocation } from "react-router-dom";
import { PORTFOLIO_DATA } from "./_data";
import { openNewWindow } from "../../utilities/function";
import PortFolioCard from "./component/PortFolioCard";

const PortfolioPage = () => {
  const { theme: COLOR } = useContext(ThemeContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let isProURL = false;

  if (pathname.search("/pro") !== -1) isProURL = true;

  let PortfolioData = PORTFOLIO_DATA;
  if (!isProURL) {
    PortfolioData = PORTFOLIO_DATA.filter((v) => v.isCurrentCompany !== true);
  }

  return (
    <>
      <div className={styled.titleContainer}>
        <p className={styled.title}>PORTFOLIO</p>
      </div>

      <div className={styled.container}>
        {PortfolioData.map((v) => (
          <PortFolioCard
            item={v}
            onClick={(id: number) => navigate(`portfolio/${id}`)}
            onOpenLink={(url: string) => {
              openNewWindow(url);
            }}
          />
        ))}
      </div>
    </>
  );
};

export default PortfolioPage;
