const PORTFOLIO_ID = {
  pantaloons: "pantaloons",
  iqvia: "iqvia",
  pinot: "pinot",
  sevak: "sevak",
};

export type ProductItemType = {
  id: string;
  title: string;
  body: string;
  image: string;
  link: string;
  youtube: string;
};

export const PORTFOLIO_DATA: ProductItemType[] = [
  {
    id: PORTFOLIO_ID.pantaloons,
    title:
      "Uhuru Multipattern USB Podcast Microphone with 2 Pickup Patterns, 0-Latancy Monitoring, Mic Gain Condenser Gaming Mic for PC (UM-1100)",
    body: "With over 24 years of retailing experience, Pantaloons, a division of Aditya Birla Fashion and Retail Ltd. is one of the most loved fashion brands in India with 344+ stores spread across 170+ towns and cities in the country.",
    image: "https://m.media-amazon.com/images/I/714wLRXXkEL._SL1500_.jpg",
    link: "https://amzn.to/3qd4k5f",
    youtube:
      "https://www.youtube.com/watch?v=JASjxeQfp_4&t=6s&ab_channel=SimDanger-NayanV.",
  },
  {
    id: PORTFOLIO_ID.iqvia,
    title: "Sony SA-D40 4.1 Channel Multimedia Speaker System with Bluetooth",
    body: "With over 24 years of retailing experience, Pantaloons, a division of Aditya Birla Fashion and Retail Ltd. is one of the most loved fashion brands in India with 344+ stores spread across 170+ towns and cities in the country.",
    image: "https://m.media-amazon.com/images/I/61EW1LZ2NHL._SL1282_.jpg",
    link: "https://amzn.to/3OODU1N",
    youtube: "",
  },
  {
    id: PORTFOLIO_ID.iqvia,
    title:
      "JBL Clip 4, Wireless Ultra Portable Bluetooth Speaker, JBL Pro Sound, Integrated Carabiner, Vibrant Colors with Rugged Fabric Design, Dust & Waterproof, Type C (Without Mic, Blue & Pink)",
    body: "With over 24 years of retailing experience, Pantaloons, a division of Aditya Birla Fashion and Retail Ltd. is one of the most loved fashion brands in India with 344+ stores spread across 170+ towns and cities in the country.",
    image: "https://m.media-amazon.com/images/I/71ZJC98VaZL._SL1500_.jpg",
    link: "https://amzn.to/3QOU7Xz",
    youtube: "",
  },
];
